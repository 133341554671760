<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M13.3333 13.3704C11.6969 13.3704 10.3704 14.6969 10.3704 16.3333C10.3704 17.9697 11.6969 19.2962 13.3333 19.2962C14.3403 19.2962 15.2299 18.7939 15.7653 18.0262C15.7696 18.0197 15.7739 18.0133 15.7783 18.0069C15.783 17.9999 15.7878 17.993 15.7927 17.9862C16.1107 17.5141 16.2962 16.9454 16.2962 16.3333C16.2962 14.6969 14.9697 13.3704 13.3333 13.3704ZM18.2951 18.2928C18.5349 17.6862 18.6666 17.0252 18.6666 16.3333C18.6666 13.3878 16.2788 11 13.3333 11C10.3878 11 8 13.3878 8 16.3333C8 19.2788 10.3878 21.6666 13.3333 21.6666C14.7315 21.6666 16.0041 21.1285 16.9552 20.2482L22.4928 24.037L16.9554 27.8258C16.0042 26.9453 14.7316 26.4072 13.3333 26.4072C10.3878 26.4072 8 28.795 8 31.7405C8 34.686 10.3878 37.0738 13.3333 37.0738C16.2788 37.0738 18.6666 34.686 18.6666 31.7405C18.6666 31.0487 18.5349 30.3877 18.2952 29.7811L24.5916 25.4731L38.1454 34.7467C38.6856 35.1163 39.4232 34.978 39.7928 34.4378C40.1624 33.8976 40.0241 33.16 39.4839 32.7904L25.308 23.0911C25.2778 23.0683 25.2468 23.0471 25.2149 23.0274L18.2951 18.2928ZM13.3333 28.7775C11.6969 28.7775 10.3704 30.1041 10.3704 31.7405C10.3704 33.3769 11.6969 34.7034 13.3333 34.7034C14.9697 34.7034 16.2962 33.3769 16.2962 31.7405C16.2962 31.1287 16.1108 30.5602 15.7931 30.0881L15.7784 30.0671L15.7651 30.0473C15.2297 29.2797 14.3401 28.7775 13.3333 28.7775ZM39.7929 13.6374C40.1625 14.1776 40.0242 14.9151 39.484 15.2848L29.4596 22.1436C28.9194 22.5132 28.1818 22.3749 27.8122 21.8347C27.4426 21.2945 27.5809 20.5569 28.1211 20.1873L38.1455 13.3285C38.6857 12.9589 39.4233 13.0972 39.7929 13.6374Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-split-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
